@function px2rem($n) {
  @return ($n/100)+rem;
}

.plan-component {
  width: px2rem(590);
  margin: px2rem(40) auto 0;
  .pre-text-box {
    display: flex;
    justify-content: space-between;
    color: #28ba98;
    font-size: px2rem(24);
    height: px2rem(30);
    line-height: px2rem(30);
    .last-text {
      color: #9aa1b1;
    }
  }
  .plan-chart {
    height: px2rem(12);
    position: relative;
    border-radius: 10px;
    background-color: #e2e2e4;
    margin-top: px2rem(16);
    .plan-line {
      position: absolute;
      width: 60%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 10px;
      background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
    }
  }
}