@function px2rem($n) {
  @return ($n/100)+rem;
}

.pie-component {
  width: px2rem(690);
  margin: px2rem(20) auto 0;
  padding: px2rem(30) px2rem(30);
  border-radius: px2rem(12);
  -webkit-backdrop-filter: blur(px2rem(18));
  backdrop-filter: blur(px2rem(18));
  box-shadow: 0 0 px2rem(30) 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  .title {
    width: fit-content;
    padding: 0 px2rem(30);
    height: px2rem(60);
    font-size: px2rem(28);
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    background-image: linear-gradient(262deg, #354052 105%, #404d66 -4%);
    margin-bottom: px2rem(30);
    border-top-left-radius: px2rem(20);
    border-bottom-right-radius: px2rem(20);
  }
  .sub-title {
    width: px2rem(630);
    text-align: center;
    font-size: px2rem(24);
    line-height: px2rem(54);
    color: #9fa2aa;
    background: #f3f7fc;
    border-radius: px2rem(8);
  }
  .chart-container {
    display: flex;
    .legend {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: px2rem(28);
      .line-item {
        display: flex;
        align-items: center;
        margin-top: px2rem(20);
        font-weight: 500;
        color: #354052;
        &.no {
          margin-top: 0;
        }
        i {
          display: block;
          width: px2rem(12);
          height: px2rem(12);
          border-radius: 50%;
          margin-right: px2rem(18);
          &.fir {
            background-color: #c61b3d;
          }
          &.sec {
            background-color: #3a7dff;
          }
        }
      }
    }
  }
  .footer {
    margin-left: px2rem(10);
    margin-top: px2rem(30);
    color: #9aa1b1;
    line-height: 1.3;
    font-size: px2rem(26);
    .pre {
      color: #9aa1b1;
      margin-bottom: px2rem(10);
      font-weight: bold;
    }
    .next{
      line-height: px2rem(50);
    }
  }
}
