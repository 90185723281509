@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100)+rem;
}

.component-text-panel {
  width: px2rem(690);
  background-image: linear-gradient(260deg, #354052 104%, #404d66 -3%);
  padding: px2rem(40);
  box-sizing: border-box;
  margin: px2rem(40) auto 0;
  position: relative;
  border-top-left-radius: px2rem(12);
  border-top-right-radius: px2rem(12);
  .right-bg {
    position: absolute;
    right: px2rem(-14);
    top: px2rem(-56);
    width: px2rem(140);
  }
  .title {
    font-size: px2rem(32);
    font-weight: 600;
    height: px2rem(60);
    line-height: px2rem(60);
    color: #ffffff;
  }
  .text {
    font-size: px2rem(28);
    color: #ffffff;
    line-height: px2rem(40);
    text-align: justify;
  }
}