@function px2rem($n) {
  @return ($n/100)+rem;
}

.tips-component {
  width: px2rem(690);
  margin: px2rem(25) auto 0;
  padding: px2rem(30) px2rem(30) px2rem(30);
  border-radius: px2rem(12);
  box-shadow: 0 0 px2rem(30) 0 rgba(53, 64, 82, 0.06);
  background-color: #ffffff;
  color: #9aa1b1;
  .header {
    font-weight: 500;
    margin: px2rem(20) 0;
    &.V2 {
      margin-top: px2rem(40);
    }
  }
  .content {
    line-height: 1.6;
  }
}
