@function px2rem($n) {
  @return ($n/100)+rem;
}

body.zh_CN {
  .page-stock-guide {
    .component-stock-money {
      .placeholder1 {
        background: url("~@/assets/img/stock-guide/zh_CN-money1.png") no-repeat 0 0  / 100%;
      }
      .placeholder2 {
        background: url("~@/assets/img/stock-guide/zh_CN-money2.png") no-repeat 0 0  / 100%;
      }
      .placeholder3 {
        background: url("~@/assets/img/stock-guide/zh_CN-money3.png") no-repeat 0 0  / 100%;
      }
      .placeholder4 {
        background: url("~@/assets/img/stock-guide/zh_CN-money4.png") no-repeat 0 0  / 100%;
      }
      .placeholder5 {
        background: url("~@/assets/img/stock-guide/zh_CN-money5.png") no-repeat 0 0  / 100%;
      }
      .placeholder6 {
        background: url("~@/assets/img/stock-guide/zh_CN-money6.png") no-repeat 0 0  / 100%;
      }
      .placeholder7 {
        background: url("~@/assets/img/stock-guide/zh_CN-money7.png") no-repeat 0 0  / 100%;
      }
      .placeholder8 {
        background: url("~@/assets/img/stock-guide/zh_CN-money8.png") no-repeat 0 0  / 100%;
      }
      .placeholder9 {
        background: url("~@/assets/img/stock-guide/zh_CN-money9.png") no-repeat 0 0  / 100%;
      }
      .placeholder10 {
        background: url("~@/assets/img/stock-guide/zh_CN-money10.png") no-repeat 0 0  / 100%;
      }
    }
  }
}
body.zh_HK {
  .page-stock-guide {
    .component-stock-money {
      .placeholder1 {
        background: url("~@/assets/img/stock-guide/zh_HK-money1.png") no-repeat 0 0  / 100%;
      }
      .placeholder2 {
        background: url("~@/assets/img/stock-guide/zh_HK-money2.png") no-repeat 0 0  / 100%;
      }
      .placeholder3 {
        background: url("~@/assets/img/stock-guide/zh_HK-money3.png") no-repeat 0 0  / 100%;
      }
      .placeholder4 {
        background: url("~@/assets/img/stock-guide/zh_HK-money4.png") no-repeat 0 0  / 100%;
      }
      .placeholder5 {
        background: url("~@/assets/img/stock-guide/zh_HK-money5.png") no-repeat 0 0  / 100%;
      }
      .placeholder6 {
        background: url("~@/assets/img/stock-guide/zh_HK-money6.png") no-repeat 0 0  / 100%;
      }
      .placeholder7 {
        background: url("~@/assets/img/stock-guide/zh_HK-money7.png") no-repeat 0 0  / 100%;
      }
      .placeholder8 {
        background: url("~@/assets/img/stock-guide/zh_HK-money8.png") no-repeat 0 0  / 100%;
      }
      .placeholder9 {
        background: url("~@/assets/img/stock-guide/zh_HK-money9.png") no-repeat 0 0  / 100%;
      }
      .placeholder10 {
        background: url("~@/assets/img/stock-guide/zh_HK-money10.png") no-repeat 0 0  / 100%;
      }
    }
  }
}
body.en,body.ko,body.ja {
  .page-stock-guide {
    .component-stock-money {
      .placeholder1 {
        background: url("~@/assets/img/stock-guide/en-money1.png") no-repeat 0 0  / 100%;
      }
      .placeholder2 {
        background: url("~@/assets/img/stock-guide/en-money2.png") no-repeat 0 0  / 100%;
      }
      .placeholder3 {
        background: url("~@/assets/img/stock-guide/en-money3.png") no-repeat 0 0  / 100%;
      }
      .placeholder4 {
        background: url("~@/assets/img/stock-guide/en-money4.png") no-repeat 0 0  / 100%;
      }
      .placeholder5 {
        background: url("~@/assets/img/stock-guide/en-money5.png") no-repeat 0 0  / 100%;
      }
      .placeholder6 {
        background: url("~@/assets/img/stock-guide/en-money6.png") no-repeat 0 0  / 100%;
      }
      .placeholder7 {
        background: url("~@/assets/img/stock-guide/en-money7.png") no-repeat 0 0  / 100%;
      }
      .placeholder8 {
        background: url("~@/assets/img/stock-guide/en-money8.png") no-repeat 0 0  / 100%;
      }
      .placeholder9 {
        background: url("~@/assets/img/stock-guide/en-money9.png") no-repeat 0 0  / 100%;
      }
      .placeholder10 {
        background: url("~@/assets/img/stock-guide/en-money10.png") no-repeat 0 0  / 100%;
      }
    }
  }
}
body.ar {
  .page-stock-guide {
    .component-stock-money {
      .sec .step li i {
        margin-right: 0;
        margin-left: px2rem(15);
      }
      .placeholder1 {
        background: url("~@/assets/img/stock-guide/en-money1.png") no-repeat 0 0  / 100%;
      }
      .placeholder2 {
        background: url("~@/assets/img/stock-guide/en-money2.png") no-repeat 0 0  / 100%;
      }
      .placeholder3 {
        background: url("~@/assets/img/stock-guide/en-money3.png") no-repeat 0 0  / 100%;
      }
      .placeholder4 {
        background: url("~@/assets/img/stock-guide/en-money4.png") no-repeat 0 0  / 100%;
      }
      .placeholder5 {
        background: url("~@/assets/img/stock-guide/en-money5.png") no-repeat 0 0  / 100%;
      }
      .placeholder6 {
        background: url("~@/assets/img/stock-guide/en-money6.png") no-repeat 0 0  / 100%;
      }
      .placeholder7 {
        background: url("~@/assets/img/stock-guide/en-money7.png") no-repeat 0 0  / 100%;
      }
      .placeholder8 {
        background: url("~@/assets/img/stock-guide/en-money8.png") no-repeat 0 0  / 100%;
      }
      .placeholder9 {
        background: url("~@/assets/img/stock-guide/en-money9.png") no-repeat 0 0  / 100%;
      }
      .placeholder10 {
        background: url("~@/assets/img/stock-guide/en-money10.png") no-repeat 0 0  / 100%;
      }
    }
  }
}
.component-stock-money {
  .sec {
    padding: 0 px2rem(30);
    background: #fff;
    padding-top: px2rem(30);
    overflow: hidden;
    .step {
      margin: px2rem(30) 0 px2rem(40);
      ul {
        display: flex;
      }
      li {
        width: px2rem(230);
        height: px2rem(60);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('~@/assets/img/trading-guide/step.png') no-repeat center center;
        background-size: 100%;
        font-size: px2rem(26);
        color: #354052;
        font-weight: 500;
        white-space: nowrap;
        i {
          width: px2rem(36);
          height: px2rem(36);
          line-height: px2rem(36);
          text-align: center;
          display: block;
          background: url('~@/assets/img/trading-guide/circle.png') no-repeat center center;
          background-size: 100%;
          margin-right: px2rem(15);
          color: #fff;
          font-size: px2rem(24);
          font-weight: 500;
          font-style: normal;
        }
      }
    }
    .placeholder {
      width: px2rem(380);
      height: px2rem(657);
      display: block;
      margin: px2rem(30) auto;
    }
    .sub-title {
      width: px2rem(650);
      line-height: px2rem(40);
      font-size: px2rem(26);
      font-weight: 500;
      color: #354052;
    }
  }
}