@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100)+rem;
}

.component-chart {
  width: px2rem(690);
  margin: px2rem(20) auto 0;
  padding: px2rem(30) px2rem(30);
  border-radius: px2rem(12);
  -webkit-backdrop-filter: blur(px2rem(18));
  backdrop-filter: blur(px2rem(18));
  box-shadow: 0 0 px2rem(30) 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  .title {
    width: fit-content;
    padding: 0 px2rem(30);
    height: px2rem(60);
    font-size: px2rem(28);
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    background-image: linear-gradient(262deg, #354052 105%, #404d66 -4%);
    margin-bottom: px2rem(30);
    border-top-left-radius: px2rem(20);
    border-bottom-right-radius: px2rem(20);
  }
  .chart-title {
    display: flex;
    line-height: px2rem(44);
    padding: px2rem(5) px2rem(20);
    font-size: px2rem(24);
    color: #354052;
    justify-content: space-between;
    border-radius: px2rem(8);
    background-color: #f3f7fc;
    > div {
      .pre {
        color: #9fa2aa;
      }
      >span {
        display: block;
      }
      &:last-child {
        text-align: right;
      }
    }
    i {
      font-style: normal;
      
      font-weight: 500;
      &.positive {
        color: #f04a5a;
      }
      &.negative {
        color: #f04a5a;
      }
    }
  }
  .highcharts-container {
    // height: px2rem(800) !important;
  }
}