@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100) + rem;
}
.dual-currency-agreement {
  padding: px2rem(50) px2rem(50) px2rem(50);
  .title {
    font-size: px2rem(48);
    margin: px2rem(20);
    font-weight: 600;
    text-align: center;
    color: #354052;
  }
  .second-title {
    font-size: px2rem(32);
    font-weight: 500;
    color: #354052;
    margin: px2rem(40) 0;
  }
  .content {
    font-size: px2rem(28);
    font-weight: normal;
    line-height: 1.85;
    color: #354052;
  }
}
