@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100) + rem;
}
.ar {
  .component-step-chia {
    .step-graph {
      transform: scaleX(-1);
    }
    .step-block1 {
      text-align: right !important;
    }
    .step-block3 {
      text-align: left !important;
    }
  }
  .component-step-chia .step-text-box .step-block.step-block1 {
    text-align: right;
  }
  .component-step-chia .step-text-box .step-block.step-block4 {
    text-align: left;
  }
}

.component-step-chia {
  width: px2rem(596);
  margin: px2rem(40) auto 0;
  .step-graph {
    position: relative;
    height: px2rem(36);
    .step-circle {
      position: absolute;
      width: px2rem(36);
      height: px2rem(36);
      top: 0;
      &.step-circle1 {
        background: url("~@/assets/img/financial-detail/icon-3@3x.png")
          no-repeat;
        background-size: 100%;
        left: 0;
      }
      &.step-circle2 {
        left: 34.13%;
        // margin-left: px2rem(-18);
        background: url("~@/assets/img/financial-detail/icon-3@3x.png")
          no-repeat;
        background-size: 100%;
      }
      &.step-circle3 {
        left: 59.7%;
        background: url("~@/assets/img/financial-detail/icon-3@3x.png")
          no-repeat;
        background-size: 100%;
      }
      &.step-circle4 {
        right: 0;
        background: url("~@/assets/img/financial-detail/icon-3@3x.png")
          no-repeat;
        background-size: 100%;
      }
      &.active {
        background: url("~@/assets/img/financial-detail/icon-2@3x.png")
          no-repeat;
        background-size: 100%;
      }
    }
    .step-line {
      position: absolute;
      height: px2rem(4);
      border-radius: px2rem(4);
      background-color: #e2e2e4;
      top: px2rem(16);
      overflow: hidden;
      i {
        display: block;
        height: 100%;
        position: absolute;
        left: 0;
        background: #15d39a;
      }
      &.active {
        background-color: #15d39a;
      }
      &.step-line1 {
        width: px2rem(170);
        left: px2rem(36);
      }
      &.step-line2 {
        width: px2rem(125);
        left: px2rem(235);
      }
      &.step-line3 {
        width: px2rem(178);
        right: px2rem(30);
      }
    }
  }
  .step-text-box {
    margin-top: px2rem(18);
    display: flex;
    justify-content: space-between;
    .step-block {
      font-size: px2rem(24);
      width: px2rem(200);
      white-space: nowrap;
      .step-text {
        display: block;
        color: #354052;
        height: px2rem(30);
        line-height: px2rem(30);
      }
      .step-time {
        display: block;
        color: #9fa2aa;
        height: px2rem(30);
        line-height: px2rem(30);
        margin-top: px2rem(4);
      }
      &.step-block1 {
        text-align: left;
      }
      &.step-block2 {
        text-align: center;
      }
      &.step-block3 {
        text-align: center;
      }
      &.step-block4 {
        text-align: right;
      }
    }
  }
}
