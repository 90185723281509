@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100)+rem;
}

.component-muti-chart {
  width: px2rem(690);
  margin: px2rem(20) auto 0;
  padding: px2rem(40) px2rem(30);
  border-radius: px2rem(12);
  -webkit-backdrop-filter: blur(px2rem(18));
  backdrop-filter: blur(px2rem(18));
  box-shadow: 0 0 px2rem(30) 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  .tab-list {
    border-radius: px2rem(30);
    background-color: #f3f7fc;
    width: px2rem(630);
    display: flex;
    li {
      width: px2rem(210);
      height: px2rem(60);
      line-height: px2rem(60);
      font-weight: 500;
      font-size: px2rem(28);
      color: #354052;
      text-align: center;
      &.active {
        border-radius: 30px;
        background-image: linear-gradient(263deg, #c61b3d 108%, #c61b3d 41%);
        color: #fff;
      }
    }
  }
  .highcharts-container {
    // height: px2rem(800) !important;
  }
}