@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100) + rem;
}
body.zh_CN {
  .page-financial-license {
    background: #c61b3d url("~@/assets/img/financial-license/3@3x.png") no-repeat;
    background-size: contain;
    .placeholder-v1 {
      background: url("~@/assets/img/financial-license/invalid-name.png")
        no-repeat 0 0 / 100%;
    }
    .placeholder-v2 {
      background: url("~@/assets/img/financial-license/hx-44-icon.png")
        no-repeat 0 0 / 100%;
    }
    .placeholder-v3 {
      background: url("~@/assets/img/financial-license/hx-logo-logo-1.png")
        no-repeat 0 0 / 100%;
    }
    .sec {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
      .placeholder-v4 {
        background: url("~@/assets/img/financial-license/license.png") no-repeat
          0 0 / 100%;
      }
      .placeholder-v5 {
        background: url("~@/assets/img/financial-license/license.png") no-repeat
          0 0 / 100%;
      }
      .placeholder-v6 {
        background: url("~@/assets/img/financial-license/license.png") no-repeat
          0 0 / 100%;
      }
      .placeholder-v7 {
        background: url("~@/assets/img/financial-license/license.png") no-repeat
          0 0 / 100%;
      }
    }
  }
}
body.zh_HK {
    .page-financial-license {
        background: #c61b3d url("~@/assets/img/financial-license/3@3x.png") no-repeat;
        background-size: contain;
        .placeholder-v1 {
          background: url("~@/assets/img/financial-license/invalid-name.png")
            no-repeat 0 0 / 100%;
        }
        .placeholder-v2 {
          background: url("~@/assets/img/financial-license/hx-44-icon.png")
            no-repeat 0 0 / 100%;
        }
        .placeholder-v3 {
          background: url("~@/assets/img/financial-license/logo-hk.png")
            no-repeat 0 0 / 100%;
        }
        .sec {
          background: #fff;
          padding-top: px2rem(30);
          overflow: hidden;
          .placeholder-v4 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
          .placeholder-v5 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
          .placeholder-v6 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
          .placeholder-v7 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
        }
      }
}
body.en,
body.ko,
body.ja {
    .page-financial-license {
        background: #c61b3d url("~@/assets/img/financial-license/3@3x.png") no-repeat;
        background-size: contain;
        .placeholder-v1 {
          background: url("~@/assets/img/financial-license/invalid-name.png")
            no-repeat 0 0 / 100%;
        }
        .placeholder-v2 {
          background: url("~@/assets/img/financial-license/hx-44-icon.png")
            no-repeat 0 0 / 100%;
        }
        .placeholder-v3 {
          background: url("~@/assets/img/financial-license/logo-en.png")
            no-repeat 0 0 / 100%;
        }
        .sec {
          background: #fff;
          padding-top: px2rem(30);
          overflow: hidden;
          .placeholder-v4 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
          .placeholder-v5 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
          .placeholder-v6 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
          .placeholder-v7 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
        }
      }
}
body.ja{
  .page-financial-license .sec .license-photos{
    min-height: px2rem(100);
  }
  .page-financial-license .bottom .common-text{
    margin-bottom: px2rem(120);
  }
}
body.ar {
    .page-financial-license {
        background: #c61b3d url("~@/assets/img/financial-license/3@3x.png") no-repeat;
        background-size: contain;
        .placeholder-v1 {
          background: url("~@/assets/img/financial-license/invalid-name.png")
            no-repeat 0 0 / 100%;
        }
        .placeholder-v2 {
          background: url("~@/assets/img/financial-license/hx-44-icon.png")
            no-repeat 0 0 / 100%;
        }
        .placeholder-v3 {
          background: url("~@/assets/img/financial-license/logo-en.png")
            no-repeat 0 0 / 100%;
        }
        .sec {
          background: #fff;
          padding-top: px2rem(30);
          overflow: hidden;
          .title-content {
            border-left: none;
            border-right: px2rem(8) solid #c61b3d;
            display: inline-block;
            padding-right: px2rem(20);
          }
          .placeholder-v4 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
          .placeholder-v5 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
          .placeholder-v6 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
          .placeholder-v7 {
            background: url("~@/assets/img/financial-license/license.png") no-repeat
              0 0 / 100%;
          }
        }
      }
}
.page-financial-license {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: px2rem(22) 0;
  .title-icon {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    padding: 0 px2rem(30);
    .placeholder-v1 {
      width: px2rem(44);
      height: px2rem(44);
    }
    .placeholder-v2 {
      width: px2rem(44);
      height: px2rem(44);
    }
  }
  .placeholder-v3 {
    width: px2rem(400);
    height: px2rem(117);
    margin: 0 auto px2rem(20);
  }
  .sec {
    margin: 0 px2rem(30);
    background: #fff;
    padding: px2rem(30) 0 px2rem(60);
    overflow: hidden;
    border-radius: px2rem(8);
    background-color: #ffffff;
    .common-component-btn {
      padding: 0 px2rem(49);
      width: px2rem(690);
      height: px2rem(73);
      line-height: px2rem(73);
      margin: 0 0 px2rem(25);
      background-color: #f3f7fc;
      color: #354052;
      font-size: px2rem(36);
      font-family: AlibabaPuHuiTiM;
      .title-content {
        border-left: px2rem(8) solid #c61b3d;
        display: inline-block;
        padding-left: px2rem(20);
      }
    }
    .sub-title {
      padding: 0 px2rem(49);
      letter-spacing: normal;
      text-align: justify;
      color: #354052;
      font-size: px2rem(26);
      line-height: 1.74;
      &.V2 {
        margin-top: px2rem(40);
      }
    }
    .license-photos {
      margin-top: px2rem(40);
      padding: 0 px2rem(49);
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      min-height: px2rem(60);
      .photo-content {
        &.V3 {
          margin-top: px2rem(40);
        }
      }
      .photo-text {
        text-align: center;
        height: px2rem(50);
        border-radius: 0 0 px2rem(6) px2rem(6);
        background-color: #c61b3d;
        color: #ffffff;
        font-size: px2rem(26);
        line-height: px2rem(50);
      }
      .placeholder-v4 {
        width: px2rem(280);
        height: px2rem(200);
        display: block;
        border: solid px2rem(1) #b9b9b9;
      }
      .placeholder-v5 {
        width: px2rem(280);
        height: px2rem(200);
        display: block;
        border: solid 1px #b9b9b9;
      }
      .placeholder-v6 {
        width: px2rem(280);
        height: px2rem(200);
        display: block;
        border: solid 1px #b9b9b9;
      }
      .placeholder-v7 {
        width: px2rem(280);
        height: px2rem(200);
        display: block;
        border: solid 1px #b9b9b9;
      }
    }
  }
  .bottom {
    .common-text {
      margin: px2rem(30) ;
      height: px2rem(38);
      text-align: center;
      opacity: 0.5;
      color: #ffffff;
    }
    .common-btn{
        margin: 0 px2rem(30);
        height: px2rem(88);
        font-size: px2rem(34);
        border-radius: px2rem(8);
        background-color: #ffffff;
        padding: px2rem(25)  px2rem(23);
        text-align: center;
        color: #c61b3d;
    }
  }
}
