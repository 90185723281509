@function px2rem($n) {
  @return ($n/100)+rem;
}

body.en {
  .tab-wrap {
    li {
      font-size: px2rem(26) !important;
    }
  }
}
body.ar {
  .ueasy-compliance {
    .tab-wrap ul li.active i {
      transform: translateX(px2rem(+20));
    }
  }
}
.ueasy-compliance {
  height: 100%;
  display: flex;
  flex-direction: column;
  .business-header {
    height: px2rem(180);
    border: 1px solid #f06328;
    border-radius: 0 0 15% 15%;
    border-top: none;

    background-color: #f06328;
    .herder-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: px2rem(108);
      .title {
        font-size: px2rem(34);
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
  .tab-wrap {
    height: px2rem(88);
    line-height: px2rem(88);
    margin-top: px2rem(20);

    // background-color: #ffffff;
    ul {
      display: flex;
      justify-content: center;
      li {
        text-align: center;
        border-radius: 7px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1),
          0 2px 10px 0 rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(to bottom, #ffffff, #ffffff);
        width: px2rem(288);
        font-size: px2rem(30);
        font-weight: 600;
        color: #354052;
        position: relative;
        cursor: pointer;
        padding: 0 px2rem(24);
        margin: px2rem(0) px2rem(20);
        i {
          position: absolute;
          bottom: 0;
          transform: translateX(px2rem(-20));
          display: none;
          width: px2rem(280);
          height: px2rem(6);
          border-bottom-left-radius: px2rem(7);
          border-bottom-right-radius: px2rem(7);
          background-color: #f06328;
          margin: px2rem(16) 0 0;
        }
        &.active {
          i {
            display: block;
          }
        }
      }
    }
  }
  .common-btn {
    width: px2rem(320);
    height: px2rem(64);
    padding: px2rem(17) px2rem(0) px2rem(17) px2rem(32);
    border-radius: px2rem(30) 0 0 0;
    background-image: linear-gradient(
      91deg,
      #000000 -2%,
      rgba(82, 99, 133, 0) 99%
    );
    color: #ffffff;
  }
  .common-component-btn {
    padding: 0 px2rem(26);
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    height: px2rem(54);
    background-image: linear-gradient(100deg, #f35023 37%, #fe7136 97%);
    color: #ffffff;
    font-size: px2rem(28);
    border-top-left-radius: px2rem(20);
    border-bottom-right-radius: px2rem(20);
  }
}
