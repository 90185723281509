@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100)+rem;
}
.component-intro {
  width: px2rem(690);
  // height: px2rem(380);
  padding: px2rem(40) 0 px2rem(20);
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: px2rem(12);
  -webkit-backdrop-filter: blur(18.1px);
  backdrop-filter: blur(px2rem(18));
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  &.en {
    li {
      width: px2rem(166);
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    padding: 0 px2rem(62);
    li {
      width: px2rem(144);
      .img-box {
        width: px2rem(110);
        height: px2rem(110);
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f7fc;
        img {
          display: block;
          width: px2rem(60);
          height: px2rem(60);
        }
      }
      .middle-title {
        line-height: px2rem(70);
        font-size: px2rem(32);
        font-weight: 500;
        text-align: center;
        color: #354052;
      }
      p {
        font-size: px2rem(24);
        color: #9fa2aa;
        line-height: px2rem(40);
        text-align: center;
      }
    }
  }
}