@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100)+rem;
}
body.ar {
  .page-kline .kline-edit-wrap span {
    padding-left: 0;
    padding-right: px2rem(30);
    i {
      right: auto;
      left: px2rem(20);
    }
  }
  .page-kline {
    .kline-resolution  {
      padding-right: px2rem(30);
    }
  }
}
.page-kline {
  background-color: #ffffff;
  width: 100%;
  // height: px2rem(680);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .kline-edit-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    // border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    align-items: center;
    
    .kline-show-bar {
      display: flex;
      height: px2rem(68);
      white-space: nowrap;
      box-sizing: border-box;
      padding: 0 px2rem(30) 0 px2rem(30);
      width: 100%;
      overflow: hidden;
      // justify-content: space-between;
      li {
        color: #6e86a8;
        font-size: px2rem(26);
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: px2rem(40);
        position: relative;
        cursor: pointer;
        &.kline-more {
          &:before {
            content: "";
            font-size: 0;
            position: absolute;
            bottom: px2rem(14);
            right: px2rem(-10);
            display: block;
            width: px2rem(10);
            height: px2rem(10);
            background: url("~@/assets/img/coin-info/nor-tri.png") no-repeat 0 0;
            background-size: 100%;
          }
          &.open {
            color: #cfd3e9;
            &::before {
              background: url("~@/assets/img/coin-info/acti-tri.png") no-repeat 0 0;
              background-size: 100%;
            }
          }
        }
        &.show-depth {
          margin-right: 0;
        }
        &.active {
          color: #3a7dff;
          &:after {
            content: "";
            width: px2rem(40);
            width: px2rem(40);
            height: px2rem(6);
            border-radius: px2rem(3);
            background-color: #3a7dff;
            position: absolute;
            left: 50%;
            margin-left: px2rem(-20);
            bottom: 0;

          }
        }
      }
    }
    .full-screen {
      width: px2rem(28);
      height: px2rem(28);
      background: url("~@/assets/img/coin-info/full-screen.png") no-repeat 0 0;
      background-size: 100%;
    }
    .sub-resolution {
      display: flex;
      position: absolute;
      height: px2rem(114);
      left: 0;
      top: px2rem(69);
      padding: 0 px2rem(30);
      width: 100%;
      align-items: center;
      border-bottom-left-radius: px2rem(12);
      border-bottom-right-radius: px2rem(12);
      box-shadow: 0 px2rem(8) px2rem(10) 0 rgba(0, 0, 0, 0.08);
      background-color: #f3f7fc;

      li {
        color: #354052;
        font-size: px2rem(26);
        margin-right: px2rem(15);
        height: px2rem(54);
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
      }
      .kline-resolution {
        min-width: px2rem(80);
        padding: 0 px2rem(14);
        display: flex;
        align-items: center;
        border-radius: px2rem(8);
        color: #6e86a8;
        font-size: px2rem(26);
        // background-color: #f3f7fc;
        justify-content: center;
        &.active {
          border-radius: px2rem(8);
          color: #f04a5a;
          border: solid 1px #f04a5a;
        }
      }
    }
  }
  .klinw-wrap {
    position: relative;
    width: 100%;
    // height: px2rem(680);
    flex: 1;
    .kline-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      box-shadow: inset 0 1px 10px 0 hsla(0,0%,77.6%,.2);
    }
    .kline-y-mask {
      position: absolute;
      width: 20%;
      height: 100%;
      right: 0;
      top: 0;
    }
    #tv_chart_container {
      width: 100%;
      height: 100%;
      >iframe {
        width: 100%;
        height: 100%;
      }
    }
    #kline-loading {
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 999;
      background: #fff;
    
      .kline-circular {
        width: px2rem(200);
        height: px2rem(200);
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    
    .is-loading {
      display: flex !important;
    }
  }
  .depth-wrap {
    position: absolute;
    width: 100%;
    height: px2rem(680);
  }
}