@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100) + rem;
}
.join-group {
  padding: 0;
  background: #fff;
  // a {
  //   font-size: px2rem(48);
  // }
  .logo {
    margin-top: px2rem(35);
    margin-bottom: px2rem(15);
    margin: px2rem(35) px2rem(40) px2rem(15);
    // width: px2rem(390);
    height: px2rem(88);
  }
  .bottom-btn {
    margin: 0 auto;
    display: block;
    width: px2rem(690);
    height: px2rem(88);
    line-height: px2rem(88);
    border-radius: px2rem(8);
    background-image: linear-gradient(276deg, #ee244c 67%, #c61b3d 137%);
    font-size: px2rem(32);
    font-weight: normal;
    text-align: center;
    color: #fff;
    &.b9 {
      background-image: linear-gradient(68deg, #fdac32 21%, #f6d789 82%);
    }
    &.v8 {
      background-image: linear-gradient(68deg, #ebac3d 21%, #f9d53b 82%);
    }
    &.Ueasy {
      background-image: linear-gradient(68deg, #e85c21 21%, #fe7136 82%);
    }
    &.maskex {
      background-image: linear-gradient(68deg, #0bd992 21%, #009bb0 82%);
    }
    &.bitda {
      background-image: linear-gradient(68deg, #a43a8a 21%, #d73b96 82%);
    }
    &.S6 {
      background-image: linear-gradient(68deg, #f7b237 21%, #f9bf32 82%);
    }
    &.a5 {
      background-image: linear-gradient(68deg, #1d2087 21%, #6153dd 82%);
    }
    &.coin8 {
      background-image: linear-gradient(68deg, #3960fb 21%, #3570ff 82%);
    }
  }
  .download-text {
    width: 100%;
    height: px2rem(100);
    margin: 0 0 px2rem(50);
    padding: px2rem(30);
    background-image: linear-gradient(276deg, #ee244c 63%, #c61b3d 437%);
    font-size: px2rem(30);
    font-weight: 500;
    color: #fff;
    &.b9 {
      background-image: linear-gradient(68deg, #ebac3d 21%, #f9d53b 82%);
    }
    &.v8 {
      background-image: linear-gradient(68deg, #ebac3d 21%, #f9d53b 82%);
    }
    &.Ueasy {
      background-image: linear-gradient(68deg, #e85c21 21%, #fe7136 82%);
    }
    &.maskex {
      background-image: linear-gradient(68deg, #0bd992 21%, #009bb0 82%);
    }
    &.bitda {
      background-image: linear-gradient(68deg, #a43a8a 21%, #d73b96 82%);
    }
    &.S6 {
      background-image: linear-gradient(68deg, #f7b237 21%, #f9bf32 82%);
    }
    &.ezex {
      background-image: linear-gradient(68deg, #ee244c 21%, #c61b3d 82%);
    }
    &.a5 {
      background-image: linear-gradient(68deg, #1d2087 21%, #6153dd 82%);
    }
    &.coin8 {
      background-image: linear-gradient(68deg, #3960fb 21%, #3570ff 82%);
    }
    a {
      color: white;
      vertical-align: middle;
    }
    img {
      float: right;
      width: px2rem(32);
      height: px2rem(32);
      vertical-align: middle;
    }
  }
  .group-info {
    height: px2rem(257);
    text-align: center;
    .group-img {
      border-radius: 50%;
      height: px2rem(100);
      margin-top: px2rem(50);
      margin-bottom: px2rem(20);
    }
    .group-name {
      font-size: px2rem(32);
      font-weight: 500;
      color: #354052;
    }
    .group-people {
      font-size: px2rem(26);
      color: #9aa1b1;
      margin-top: px2rem(15);
    }
  }
  .remark-content {
    margin-top: px2rem(40);
    padding: 0 px2rem(30) px2rem(30);
    .remark-title {
      margin-bottom: px2rem(20);
      font-size: px2rem(30);
      font-weight: 500;
      color: #354052;
    }
    .remark {
      font-size: px2rem(28);
      font-weight: normal;
      color: #9aa1b1;
    }
  }
}
