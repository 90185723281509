@function px2rem($n) {
  @return ($n/100)+rem;
}

body.en {
  .tab-wrap {
    li {
      font-size: px2rem(26) !important;
    }
  }
}
.page-stock-guide {
  background-color: #f8f8f8;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tab-wrap {
    height: px2rem(88);
    line-height: px2rem(88);
    background-color: #f3f7fc;
    ul {
      display: flex;
      justify-content: center;
      li {
        font-size: px2rem(30);
        font-weight: 600;
        color: #354052;
        position: relative;
        cursor: pointer;
        padding: 0 px2rem(24);
        i {
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(px2rem(-20));
          display: none;
          width: px2rem(40);
          height: px2rem(6);
          border-radius: px2rem(3);
          background-color: #c61b3d;
        }
        &.active {
          i {
            display: block;
          }
        }
      }
    }
  }
  .common-component-btn {
    padding: 0 px2rem(26);
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    height: px2rem(54);
    background-image: linear-gradient(100deg, #e83559 37%, #980522 97%);
    color: #ffffff;
    font-size: px2rem(28);
    border-top-left-radius: px2rem(20);
    border-bottom-right-radius: px2rem(20);
  }
}