@function px2rem($n) {
  @return ($n/100)+rem;
}

@import '~@/assets/font/din-pro/index.scss';
html,body,#root {
  max-width: 750px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #ffffff;
}
html {
  overflow:hidden
  &.black {
    background-color: #131f30;
    body {
      background-color: #131f30;
    }
  }
  &.white {
    background: rgba(256, 256, 256, 0.7);
    body {
      background: rgba(256, 256, 256, 0.7);
    }
  }
}
body {
  font-family: pingfang sc,microsoft yahei,san francisco,Arial,sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow: hidden;
  background: #ffffff;
  &.ar {
    direction: rtl;
  }
}
#root {
  overflow: auto;
  &::-webkit-scrollbar{width:0;height:0}
}
