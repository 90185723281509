@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100)+rem;
}

.ar {
 .icon-component-rate {
   .schedule {
     right: 0 !important;
     left: auto;
     border-radius: 0;
     border-bottom-left-radius: px2rem(20);
   }
 } 
}

.icon-component-rate {
  overflow: hidden;
  padding: px2rem(50) 0 px2rem(40);
  width: px2rem(690);
  margin: px2rem(18) auto 0;
  border-radius: px2rem(12);
  -webkit-backdrop-filter: blur(px2rem(18));
  backdrop-filter: blur(px2rem(18));
  box-shadow: 0 0 px2rem(30) 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  position: relative;
  border-top-left-radius: px2rem(20);
  .expire-time-wrap {
    position: absolute;
    right: px2rem(30);
    top: px2rem(50);
    height: px2rem(80);
    line-height: px2rem(80);
    font-size: px2rem(30);
    .expire-des {
      color: #354052;
    }
    .expire-num {
      margin-left: px2rem(10);
      color: #9aa1b1;
    }
  }
  .schedule {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 px2rem(30);
    height: px2rem(60);
    line-height: px2rem(60);
    font-weight: 500;
    text-align: center;
    color: #fff;
    border-top-left-radius: px2rem(20);
    border-bottom-right-radius: px2rem(20);
    &.accruing {
      background: rgb(196, 196, 198);
    }
    &.warming {
      background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
    }
    &.recruit {
      background-image: linear-gradient(259deg, #ff486d 106%, #c61b3d 40%);
    }
    &.storage {
      background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
    }
    &.issuing {
      background: rgb(196, 196, 198);
    }
  }
  .rate-wrap {
    display: flex;
    padding: 0 px2rem(40);
    .rate-num {
      height: px2rem(80);
      line-height: px2rem(80);
      color: #f04a5a;
      font-size: px2rem(48);
      display: block;
      text-align: center;
      font-weight: 600;
    }
    .rate-year-num {
      font-size: px2rem(24);
      height: px2rem(80);
      line-height: px2rem(80);
      display: block;
      text-align: center;
      color: #9fa2aa;
      margin-left: px2rem(13);
    }
  }
  .rate-lock-wrap {
    display: flex;
    width: px2rem(400);
    justify-content: space-between;
    margin: px2rem(20) auto 0;
    &.en {
      width: px2rem(500);
    }
    .rate-area {
      .rate-num {
        color: #f04a5a;
      }
    }
    .lock-area {

    }
    .rate-num {
      height: px2rem(80);
      line-height: px2rem(80);
      color: #354052;
      font-weight: 600;
      font-size: px2rem(48);
      display: block;
      text-align: center;
    }
    .rate-year-num {
      font-size: px2rem(24);
      height: px2rem(30);
      line-height: px2rem(30);
      display: block;
      text-align: center;
      color: #9fa2aa;
    }
  }
  .total-rate {
    width: px2rem(590);
    margin: px2rem(28) auto 0;
    .total-num-box {
      display: flex;
      justify-content: space-between;
      font-size: px2rem(24);
      height: px2rem(30);
      line-height: px2rem(30);
      font-weight: 500;
      .total-num {
        color: #9fa2aa;
      }
      .already-num {
        color: #15d39a;
      }
    }
    .total-line-box {
      width: px2rem(590);
      height: px2rem(12);
      border-radius: px2rem(10);
      background-color: #e2e2e4;
      margin-top: px2rem(16);
      i {
        display: block;
        height: 100%;
        border-radius: px2rem(10);
        background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
        width: 60%;
      }
    }
  }
  .tip {
    width: px2rem(630);
    padding: px2rem(10) px2rem(30);
    box-sizing: border-box;
    min-height: px2rem(60);
    line-height: px2rem(40);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: px2rem(30);
    border: solid px2rem(1) #e2e2e4;
    background-color: #f3f7fc;
    color: #354052;
    font-size: px2rem(28);
    font-weight: 500;
    margin: px2rem(30) auto 0;
    text-align: justify;
  }
  .tip-box {
    width: px2rem(610);
    margin: 0 auto;
    padding-top: px2rem(28);
    margin-top: px2rem(19);
    position: relative;

    &:before {
      content: "";
      width: 200%;
      height: 200%;
      border-top: 1px solid #eee;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      box-sizing: border-box;
    }
    .line-item {
      display: flex;
      height: px2rem(48);
      line-height: px2rem(48);
      justify-content: space-between;
      color: #354052;
      font-size: px2rem(28);
      font-weight: 500;
      .next {
        max-width: px2rem(400);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}