@function px2rem($n) {
  @return ($n/100)+rem;
}

.page-fee-detail {
  padding: px2rem(30) px2rem(30) px2rem(60);
  table {
    line-height: px2rem(46);
    text-align: left;
    border-collapse: collapse;
    width: px2rem(690);

    th,
    td {
      border: 1px solid #c3c7cf;
      margin: 0;
      padding: 0;
      padding-left: px2rem(20);
      padding-top: px2rem(10);
      padding-bottom: px2rem(10);
      &:last-child {
        width: px2rem(160);
      }
    }
    th {
      color: #fff;
      font-weight: 500;
      background: #374050;
      font-size: px2rem(24);
    }
    td {
      color: #354052;
      font-size: px2rem(22);
    }
    .first {
      padding-right: px2rem(20);
      background: #f4f7fb;
    }
  }
}
