@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100)+rem;
}
#root {
  font-size: px2rem(28);
}
.defiMining-info {
  background: #fff;
  overflow: hidden;
  .gap {
    display: block;
    height: px2rem(36);
  }
}