@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100) + rem;
}
body.ar {
  .fortest .am-popover-content .am-popover-arrow {
    top: px2rem(13) !important;
    background: #354052;
    right: px2rem(310) !important;
  }
  .component-rule-chia {
    .line-item {
      .pre {
        padding-right: px2rem(30);
        i {
          left: auto;
          right: 0;
        }
      }
    }
    .go-to-intrduction {
      float: left;
    }
  }
  .component-rule-chia .tip-box .line-item .next {
    text-align: left;
  }
}
body.en {
  .fortest .am-popover-content .am-popover-arrow {
    top: px2rem(13) !important;
    background: #354052;
    left: px2rem(400) !important;
  }
}
body.ja {
  .fortest .am-popover-content .am-popover-arrow {
    top: px2rem(13) !important;
    background: #354052;
    left: px2rem(376) !important;
  }
  .component-rule-chia .go-to-intrduction {
    width: px2rem(260);
  }
}
body.ko {
  .fortest .am-popover-content .am-popover-arrow {
    top: px2rem(13) !important;
    background: #354052;
    left: px2rem(300) !important;
  }
}

.component-rule-chia {
  width: px2rem(690);
  box-sizing: border-box;
  margin: px2rem(20) auto 0;
  padding: px2rem(40) px2rem(30) px2rem(32);
  border-radius: px2rem(12);
  -webkit-backdrop-filter: blur(px2rem(18));
  backdrop-filter: blur(px2rem(18));
  box-shadow: 0 0 px2rem(30) 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  .title {
    width: fit-content;
    padding: 0 px2rem(30);
    height: px2rem(60);
    margin-top: px2rem(26);
    font-size: px2rem(28);
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    background-image: linear-gradient(262deg, #354052 105%, #404d66 -4%);
    margin-bottom: px2rem(30);
    border-top-left-radius: px2rem(20);
    border-bottom-right-radius: px2rem(20);
    &.second-title {
      margin-top: px2rem(30);
    }
  }
  .content {
    p {
      line-height: 1.6;
      color: #9aa1b1;
    }
  }
  .rule-top {
    display: flex;
    align-items: center;
    height: px2rem(80);
    justify-content: space-between;
    .left {
      display: flex;
      height: px2rem(60);
      align-items: center;

      .placeholder-rule {
        width: px2rem(36);
        height: px2rem(36);
        margin-left: px2rem(15);
        // display: inline-block;
        // vertical-align: middle;
        // margin-top: px2rem(-4);
        background: url("~@/assets/img/ico/info.png") no-repeat 0 0 / 100%;
      }
    }
  }

  .go-to-intrduction {
    // float: right;
    color: #9aa1b1;
    // margin-top: px2rem(-80);
    .placeholder-v3 {
      width: px2rem(36);
      height: px2rem(36);
      display: inline-block;
      vertical-align: middle;
      margin-top: px2rem(-4);
      background: url("~@/assets/img/ico/info.png") no-repeat 0 0 / 100%;
    }
  }
  .tip-box {
    width: px2rem(610);
    margin: 0 auto;
    padding-top: px2rem(18);
    margin-top: px2rem(12);
    position: relative;
    .line-item {
      display: flex;
      // height: px2rem(48);
      line-height: px2rem(48);
      justify-content: space-between;
      color: #9aa1b1;
      font-size: px2rem(28);
      .next {
        max-width: px2rem(400);
        text-align: right;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
      }
    }
  }

  .component-step {
    margin-bottom: px2rem(40);
  }
  .footer {
    margin-left: px2rem(10);
    margin-top: px2rem(30);
    color: #9aa1b1;
    line-height: 1.3;
    font-size: px2rem(26);
    .pre {
      color: #9aa1b1;
      margin-bottom: px2rem(10);
    }
  }
}
.know-modal {
  width: px2rem(640) !important;
  // height: px2rem(400);
  .common-title {
    height: px2rem(48);
    color: #354052;
    font-size: px2rem(34);
    font-weight: 600;
    padding: px2rem(0) px2rem(0) px2rem(0) px2rem(25);
    text-align: left;
  }
  .common-text {
    padding: px2rem(22) px2rem(0) px2rem(40) px2rem(25);
    color: #354052;
    font-size: px2rem(30);
    text-align: left;
  }
  .know-buttons {
    display: flex;
    justify-content: center;
    // width: px2rem(560);
    height: px2rem(80);
    background-color: #c61b3d;
    border-radius: 8px;
    color: #ffffff;
    line-height: px2rem(80);
  }
}

.fortest {
  left: px2rem(0) !important;
  margin: px2rem(20);
  padding: px2rem(20);
  opacity: 0.9;
  .am-popover-arrow {
    top: px2rem(13) !important;
    background: #354052;
    left: px2rem(266) !important;
  }
  .am-popover-inner {
    border-radius: px2rem(12);
    .am-popover-inner-wrapper {
      background-color: #354052;
      color: #fff;
      .am-popover-item-container {
        padding: px2rem(20) px2rem(8);
        height: auto;
      }
    }
  }
}
