@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100) + rem;
}

body.zh_CN {
  .stock-business{
    .sec {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
      .placeholder-v4 {
        background: url("~@/assets/img/financial-license/hkpz.png") no-repeat
          0 0 / 100%;
      }
    }
  }
}
body.zh_HK  {
  .stock-business{
    .sec {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
      .placeholder-v4 {
        background: url("~@/assets/img/financial-license/hkpz.png") no-repeat
          0 0 / 100%;
      }
    }
  }
}
body.en,body.ko,body.ja {
  .stock-business{
    .sec {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
      .sub-title{
        text-align:left
      }

      .placeholder-v4 {
        background: url("~@/assets/img/financial-license/hkpz.png") no-repeat
          0 0 / 100%;
      }
    }
  }
}
body.ar {
  .stock-business{
    .sec {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
      .placeholder-v4 {
        background: url("~@/assets/img/financial-license/hkpz.png") no-repeat
          0 0 / 100%;
      }
    }
  }
}
  .stock-business{
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
    padding-top: px2rem(70);
      .title{
        font-family: AlibabaPuHuiTiM;
        font-size: px2rem(34);
        text-align: center;
        color: #354052;
      }
      .sec {
        margin: 0 px2rem(30);
        background: #fff;
        padding: px2rem(30) 0 px2rem(10);
        overflow: hidden;
        border-radius: px2rem(8);
        background-color: #ffffff;
        .common-component-btn {
          padding: 0 px2rem(49);
          width: px2rem(690);
          height: px2rem(73);
          line-height: px2rem(73);
          margin: 0 0 px2rem(25);
          background-color: #f3f7fc;
          color: #354052;
          font-size: px2rem(36);
          font-family: AlibabaPuHuiTiM;
          .title-content {
            border-left: px2rem(8) solid #c61b3d;
            display: inline-block;
            padding-left: px2rem(20);
          }
        }
        .sub-title {
          padding: 0 px2rem(49);
          letter-spacing: normal;
          text-align: justify;
          color: #354052;
          font-size: px2rem(26);
          line-height: 1.74;
          &.V2 {
            margin-top: px2rem(40);
          }
        }
        .license-photos {
          width:  px2rem(340);
          height: px2rem(480);
          display: flex;
          padding: px2rem(12) px2rem(8) px2rem(12) px2rem(12);
          margin: px2rem(50) auto 0;
          border-radius: px2rem(5);
          box-shadow: 0 0 px2rem(10) 0 rgba(0, 0, 0, 0.14);
          background-color: #ffffff;

          // .photo-content {
          //   &.V3 {
          //     margin-top: px2rem(40);
          //   }
          // }
          .photo-text {
            text-align: center;
            height: px2rem(50);
            border-radius: 0 0 px2rem(6) px2rem(6);
            background-color: #c61b3d;
            color: #ffffff;
            font-size: px2rem(26);
            line-height: px2rem(50);
          }
          .placeholder-v4 {
            width: 100%;
            display: block;
            margin:px2rem(12)
          }
          
        }
      }
      .bottom {
         margin-bottom: px2rem(200);
        .common-text {
          margin: px2rem(30) ;
          height: px2rem(38);
          font-size: px2rem(34);
          font-weight: 500;
          font-family: PingFangSC;
          text-align: center;
          color: #354052;
        }
        .common-btn{
            margin: 0 px2rem(30);
            height: px2rem(88);
            font-size: px2rem(34);
            border-radius: px2rem(8);
            background-color: #ffffff;
            padding: px2rem(25)  px2rem(23);
            text-align: center;
            color: #c61b3d;
        }
      }
  }