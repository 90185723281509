@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100) + rem;
}
body.zh_CN {
  .stock-ueasy {
    .sec2 {
      .placeholder {
        background: url("~@/assets/img/trading-guide/zh_CN-rule1.png") no-repeat
          0 0 / 100%;
      }
    }
    .sec3 {
      .placeholder-v1 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v2 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v3 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v4 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v5 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
    }

    .sec2 {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
      .placeholder-v11 {
        background: url("~@/assets/img/financial-license/mgpz-ever.png")
          no-repeat 0 0 / 100%;
      }
      .placeholder-v22 {
        background: url("~@/assets/img/financial-license/jndpz-ever.png")
          no-repeat 0 0 / 100%;
      }
    }

    .sec4 {
      .inline-content {
        .icon6 {
          background: url("~@/assets/img/financial-license/icon6-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon7 {
          background: url("~@/assets/img/financial-license/icon7-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon8 {
          background: url("~@/assets/img/financial-license/icon8-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon9 {
          background: url("~@/assets/img/financial-license/icon9-ueasy.png")
            no-repeat 0 0 / 100%;
        }
      }
    }
  }
}
body.zh_HK {
  .stock-ueasy {
    .sec2 {
      .placeholder {
        background: url("~@/assets/img/trading-guide/zh_CN-rule1.png") no-repeat
          0 0 / 100%;
      }
    }
    .sec3 {
      .placeholder-v1 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v2 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v3 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v4 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v5 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
    }

    .sec2 {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
      .placeholder-v11 {
        background: url("~@/assets/img/financial-license/mgpz-ever.png")
          no-repeat 0 0 / 100%;
      }
      .placeholder-v22 {
        background: url("~@/assets/img/financial-license/jndpz-ever.png")
          no-repeat 0 0 / 100%;
      }
    }
    .sec4 {
      .inline-content {
        .icon6 {
          background: url("~@/assets/img/financial-license/icon6-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon7 {
          background: url("~@/assets/img/financial-license/icon7-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon8 {
          background: url("~@/assets/img/financial-license/icon8-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon9 {
          background: url("~@/assets/img/financial-license/icon9-ueasy.png")
            no-repeat 0 0 / 100%;
        }
      }
    }
  }
}
body.en,
body.ja {
  .stock-ueasy {
    .sec {
      &.sec2 {
        .license-photos {
          padding: 0;
        }
      }
      .license-photos {
        justify-content: flex-end;
      }
    }
  }
}
body.en,
body.ko,
body.ja {
  .stock-ueasy {
    .sec {
      .common-btn {
        width: px2rem(480);
      }
    }
    .sec2 {
      .placeholder {
        background: url("~@/assets/img/trading-guide/zh_CN-rule1.png") no-repeat
          0 0 / 100%;
      }
    }
    .sec3 {
      .placeholder-v1 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v2 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v3 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v4 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v5 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
    }

    .sec2 {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
      .placeholder-v11 {
        background: url("~@/assets/img/financial-license/mgpz-ever.png")
          no-repeat 0 0 / 100%;
      }
      .placeholder-v22 {
        background: url("~@/assets/img/financial-license/jndpz-ever.png")
          no-repeat 0 0 / 100%;
      }
    }
    .sec4 {
      .inline-content {
        .icon6 {
          background: url("~@/assets/img/financial-license/icon6-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon7 {
          background: url("~@/assets/img/financial-license/icon7-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon8 {
          background: url("~@/assets/img/financial-license/icon8-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon9 {
          background: url("~@/assets/img/financial-license/icon9-ueasy.png")
            no-repeat 0 0 / 100%;
        }
      }
    }
  }
}
body.ar {
  .stock-ueasy {
    .sec {
      .common-btn {
        width: px2rem(320);
      }
    }
    .sec2 {
      .placeholder {
        background: url("~@/assets/img/trading-guide/zh_CN-rule1.png") no-repeat
          0 0 / 100%;
      }
    }
    .sec3 {
      .placeholder-v1 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v2 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v3 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v4 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v5 {
        background: url("~@/assets/img/financial-license/icon1.png") no-repeat 0
          0 / 100%;
      }
    }

    .sec2 {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
      .placeholder-v11 {
        background: url("~@/assets/img/financial-license/mgpz-ever.png")
          no-repeat 0 0 / 100%;
      }
      .placeholder-v22 {
        background: url("~@/assets/img/financial-license/jndpz-ever.png")
          no-repeat 0 0 / 100%;
      }
    }
    .sec4 {
      .inline-content {
        .icon6 {
          background: url("~@/assets/img/financial-license/icon6-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon7 {
          background: url("~@/assets/img/financial-license/icon7-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon8 {
          background: url("~@/assets/img/financial-license/icon8-ueasy.png")
            no-repeat 0 0 / 100%;
        }
        .icon9 {
          background: url("~@/assets/img/financial-license/icon9-ueasy.png")
            no-repeat 0 0 / 100%;
        }
      }
    }
  }
}
.stock-ueasy {
  overflow: auto;
  background-color: #fff;
  margin-top: 10px;
  .big-img {
    width: 100%;
    height: 100%;
    .placeholder {
      width: 100%;
      height: 100%;
      display: block;
      background: url("~@/assets/img/financial-license/licence1-ueasy.png")
        no-repeat 0 0 / 100%;
    }
  }
  .sec {
    padding: 0 px2rem(40);
    background: #fff;
    padding-top: px2rem(40);
    overflow: hidden;
    &.sec1 {
      margin-top: px2rem(13);
      .sub-content {
        font-size: px2rem(30);
        line-height: px2rem(60);
        &.v2 {
          margin-top: px2rem(30);
        }
      }
    }
    // &.sec2,&.sec3,&.sec4,&.sec5 {
    //   margin-top: px2rem(30);
    // }
    &.sec5 {
      margin-bottom: px2rem(40);
    }
    &.sec6 {
      margin-bottom: px2rem(60);
    }
    .common-btn {
      &.v2 {
        margin-top: px2rem(40);
      }
    }
    .sub-title {
      height: px2rem(58);
      line-height: px2rem(58);
      color: #354052;
      font-size: px2rem(30);
      font-weight: 600;
      margin-top: px2rem(20);
    }
    .sub-content {
      line-height: px2rem(32);
      color: #354052;
      font-size: px2rem(24);
      margin-top: px2rem(5);
    }
    .license-photos {
      margin-top: px2rem(40);
      padding: 0 px2rem(49);
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      min-height: px2rem(60);
      .photo-content {
        width: px2rem(280);
        height: px2rem(390);
        padding: px2rem(8) px2rem(5) px2rem(9) px2rem(8);
        border-radius: px2rem(4);
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
        background-color: #ffffff;
        .placeholder-v11 {
          height: 100%;
          display: block;
        }
        .placeholder-v22 {
          height: 100%;
          display: block;
        }
        &.V3 {
          // margin-top: px2rem(40);
        }
      }
      .photo-text {
        margin-top: px2rem(20);
        font-weight: bolder;
        text-align: center;
        height: px2rem(50);
        border-radius: 0 0 px2rem(6) px2rem(6);
        color: #354052;
        font-size: px2rem(30);
        line-height: px2rem(50);
      }
    }

    &.sec3 {
      .sub-title {
        .placeholder {
          margin-top: px2rem(-2);
          display: inline-block;
          width: px2rem(42);
          height: px2rem(42);
          line-height: px2rem(42);
          vertical-align: middle;
          margin-right: px2rem(18);
        }
      }
      .sub-content {
        padding-left: px2rem(60);
      }
    }

    &.sec4 {
      .inline-content {
        display: flex;
        justify-content: center;
        padding: 0 px2rem(28);
        text-align: center;
        margin-top: px2rem(30);
        li {
          display: inline-block;
          padding: 0 px2rem(7);
          span {
            display: block;
            width: px2rem(80);
            height: px2rem(80);
            margin: 0 auto px2rem(18);
          }
        }
      }
      .common-content {
        // height: px2rem(180);
        margin: px2rem(28) 0 0;
        padding: px2rem(30) px2rem(25) px2rem(20) px2rem(35);
        background-image: linear-gradient(68deg, #f35023 21%, #fe7136 82%);
        border-top-left-radius: px2rem(50);
        color: #fff;
      }
    }
  }
}
