@function px2rem($n) {
  @return ($n/100)+rem;
}
.DINPro {
  font-family: 'DINPro', "Microsoft Yahei", "PingFang SC", "San Francisco", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  // letter-spacing: px2rem(-1.5);
}

// 字体是由字重和字体名决定的，采用base64 压缩
@font-face {
  font-family: "DINPro";
  src: url("~@/assets/font/din-pro/DINPro-Medium.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
