@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100) + rem;
}
.not-supported {
  .no-data {
    display: block;
    width: px2rem(200);
    height: px2rem(200);
    margin: 0 auto;
    background: url("~@/assets/img/common/no-data.png") no-repeat 0 0 / 100%;
  }
  margin-top: 50%;
  font-size: px2rem(40);
  text-align: center;
  color: rgb(194, 199, 208);
}
