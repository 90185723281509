@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100) + rem;
}
body.zh_CN {
  .ico-introduction {
    // .placeholder-v2 {
    //   background: url("~@/assets/img/financial-license/hx-44-icon.png")
    //     no-repeat 0 0 / 100%;
    // }
    // .placeholder-v3 {
    //   background: url("~@/assets/img/financial-license/hx-logo-logo-1.png")
    //     no-repeat 0 0 / 100%;
    // }
    .sec {
      background: #fff;
      padding-top: px2rem(30);
      overflow: hidden;
    
    }
  }
}
body.zh_HK {
  .ico-introduction {
  }
}
body.en,
body.ko,
body.ja {
  .ico-introduction {
   
  }
}
body.ja {
  .ico-introduction .sec .license-photos {
    min-height: px2rem(100);
  }
  .ico-introduction .bottom .common-text {
    margin-bottom: px2rem(120);
  }
}
body.ar {
  .ico-introduction {
    .sec .title-content{
      text-align: right;
    }
    .sec .info-content{
      border-left: none;
      padding-right:px2rem(20);
      border-right:px2rem(6) solid #0dbaaf;
    }
  }
}
.ico-introduction {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: px2rem(22) px2rem(44);
  .sec {
    .title-content {
      text-align: left;
      &.V2{
        margin-top: px2rem(50);
      }
      .placeholder-icon {
        width: px2rem(70);
        height: px2rem(70);
        display: inline-block;
        vertical-align: middle;
        background: url("~@/assets/img/ico/ico-logo.png") no-repeat 0 0 / 100%;
        margin-top: px2rem(15);
      }
      .ico-text {
        display: inline-block;
        font-size: px2rem(34);
        font-weight: 600;
        color: #354052;
        height: px2rem(70);
        line-height: px2rem(70);
        vertical-align: middle;
        // color: rgb(53 64 82);
      }
    }
    .sub-title {
      margin-top: px2rem(32);
      font-size: px2rem(30);
      color: #354052;
      font-weight: 500;
      &.V2 {
        font-weight: normal;
        font-size: px2rem(28);
        color: #354052;
        line-height: 1.71;
      }
      &.V3 {
        margin-top: px2rem(0);
        font-weight: normal;
        font-size: px2rem(28);
        color: #354052;
        line-height: 1.71;
      }
    }
    .info-content {
      border-left: px2rem(6) solid #0dbaaf;
      padding-left: px2rem(10);
      margin-top: px2rem(16);
      p {
        font-size: px2rem(26);
        line-height: 1.85;
        color: #182230;
        margin: 0 0 0 px2rem(30);
        &.first-item{
          margin-top: px2rem(18);
        }
      }
    }
    .license-photos {
      height: px2rem(270);
      .placeholder-v1 {
        margin-top: px2rem(35);
        height: px2rem(352);
        width: px2rem(650);
        display: block;
        background: url("~@/assets/img/ico/ico-invalid-name.png") no-repeat 0 0 /
          100%;
      }
    }
  }
  .secTwo {
    margin-top: px2rem(40);
    .title-content {
      width: px2rem(650);
      height: px2rem(336);
      color: #354052;
      line-height: 1.71;
      font-size: px2rem(28);
      &.V4{
        height: auto;
        margin-top: px2rem(50);
      }
    }
    .license-photos {
      height: px2rem(374);
      &.V3{
        height: px2rem(274);
      }
      &.V4{
        height: px2rem(374);
      }
      .placeholder-v2 {
        margin-top: px2rem(35);
        height: px2rem(374);
        width: px2rem(650);
        display: block;
        background: url("~@/assets/img/ico/icon-invalid-name2.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v3 {
        margin-top: px2rem(35);
        height: px2rem(374);
        width: px2rem(650);
        display: block;
        background: url("~@/assets/img/ico/icon-invalid-name3.png") no-repeat 0
          0 / 100%;
      }
      .placeholder-v4 {
        margin-top: px2rem(35);
        height: px2rem(374);
        width: px2rem(650);
        display: block;
        background: url("~@/assets/img/ico/icon-invalid-name4.png") no-repeat 0
          0 / 100%;
      }
    }
    .title-contentV2 {
      margin-top: px2rem(40);
      height: px2rem(42);
      line-height: px2rem(42);
      font-size: px2rem(30);
      font-weight: 600;
      color: #182230;
    }

    .footer-content {
      margin-top: px2rem(40);
      p {
        height: px2rem(32);
        line-height: px2rem(32);
        font-size: px2rem(28);
        font-weight: 500;
        color: #354052;
        vertical-align: middle;
        .placeholder-v3 {
          margin-top: px2rem(-5);
          margin-right: px2rem(10);
          height: px2rem(32);
          width: px2rem(32);
          display: inline-block;
          vertical-align: middle;
          background: url("~@/assets/img/ico/hx-32.png") no-repeat 0 0 / 100%;
        }
      }
      .url {
        display: block;
        margin-bottom: px2rem(20);
        color: #3a7dff;
        cursor: pointer;
        margin-left: px2rem(40);
      }
    }
    .footer-contentV2 {
      height: px2rem(60);
    }
  }
}
