@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100)+rem;
}
.lds-ring {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(256, 256, 256, 0.7);
  .circle-loading-v2 {
    position: absolute;
    width: px2rem(200);
    height: px2rem(200);
    left: 50%;
    top: 50%;
    margin-left: px2rem(-100);
    margin-top: px2rem(-100);
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}