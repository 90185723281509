@function px2rem($n) {
  @return ($n/100)+rem;
}

@function px2rem($n) {
  @return ($n/100)+rem;
}
.page-support-article {
  // margin-top: px2rem(22);
  padding: px2rem(22) px2rem(40);
  background: #fff;
  height: 100%;
  .article-title {
    height: px2rem(62);
    line-height: px2rem(62);
    font-size: px2rem(34);
    color: #354052;
  }
  .article-date {
    color: #b4bac6;
    font-size: px2rem(24);
    height: px2rem(24);
    line-height: px2rem(24);
  }
  .article-content {
    line-height: px2rem(48);
    color: #717985;
    margin-top: px2rem(40);
    word-break: break-all;
    white-space: pre-wrap;
    padding-bottom: px2rem(20);
    video {
      max-width: px2rem(660);
      margin: 0 auto;
    }
    img {
      max-width: px2rem(660);
    }
  }
}